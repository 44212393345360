<template>
  <section>
    <div v-if="isFromDocument || isAfterDocumentSend">
      <div
        v-if="
          data.validations.multiple &&
          data.input_type != 'ENTITY_VARIABLE' &&
          !checkReadonly()
        "
      >
        <el-upload
          :style="getStyle"
          :on-change="upload"
          class="avatar-uploader"
          :show-file-list="false"
          multiple
          :file-list="fileList"
          ref="upload"
          action="/upload"
          :limit="data.validations.max_document_files"
          :accept="stringifiedArray"
          :on-exceed="handleExceed"
          :on-remove="removeFile"
          :class="{ 'disabled-div': readonly() }"
          @change="applyFormRules"
          :disabled="isDisable"
          :auto-upload="false"
          v-loading="uploadLoadig"
        >
          <img
            v-if="
              !form[data.key] &&
              data.input_type != 'ENTITY_VARIABLE' &&
              !checkReadonly()
            "
            src="@/assets/img/icons/upload-icon.svg"
            alt="icon"
          />
        
          <span
            v-else-if="
              (form &&
              form[data.key] &&
              form[data.key].files &&
              form[data.key].files.length &&
              form[data.key].files.length > 0) || 
              (form &&
              form[data.key] &&
              form[data.key][0] && 
              form[data.key][0].name) &&
              !checkReadonly()
            "
            @click.stop="uploadDocumentsPopup()"
          >
            {{ form[data.key] && form[data.key][0] &&  form[data.key][0].name ? form[data.key][0].name  : form[data.key].name  }}  
            <el-tag
            :disabled="isDisable"
              style="cursor: pointer; margin-top: -14px"
              class="ml-1"
              :value="this.files.length ? this.files.length : form[data.key] && form[data.key].files && form[data.key].files.length"
              v-if="form[data.key] && form[data.key].files && form[data.key].files.length && form[data.key].files.length > 1 || this.files.length > 1 && !checkReadonly()"
              @click.stop="uploadDocumentsPopup()"
            >
             <span  v-if="form[data.key] && form[data.key].files && form[data.key].files.length && form[data.key].files.length > 1"> +{{form[data.key].files.length - 1}} </span>
             <span v-else> +{{ this.files.length - 1 }} </span>
            </el-tag>
          </span>
          <!-- </span> -->
          <i
            v-if="data.input_type != 'ENTITY_VARIABLE' && !checkReadonly()"
            class="el-icon-upload2 ml-1"
          ></i>
        </el-upload>
      </div>
    <div v-if="!data.validations.multiple">

      <el-upload
          :style="getStyle"
          :on-change="upload"
          class="avatar-uploader"
          :show-file-list="false"
          :file-list="fileList"
          ref="upload"
          action="/upload"
          :accept="stringifiedArray"
          :on-exceed="handleExceed"
          :on-remove="removeFile"
          :class="{ 'disabled-div': readonly() }"
          @change="applyFormRules"
          :disabled="isDisable"
          :auto-upload="false"
          v-loading="uploadLoadig"
        >
          <img
            v-if="form[data.key] && form[data.key].files && form[data.key].files.length === 0 || !form[data.key]"
            src="@/assets/img/icons/upload-icon.svg"
            alt="icon"
          />
          <span v-else-if="form[data.key] && form[data.key].files && form[data.key].files.length && form[data.key].files.length > 0 && form[data.key].files[0]"> {{ form[data.key].files[0].name }}<i
           
            class="el-icon-upload2 ml-1"
          ></i></span>
          <span v-else>
            {{ data.files[0].name }}
            <i class="el-icon-upload2"></i>
          </span>
        </el-upload>
    </div>
  </div>
  <div v-if="!isFromDocument && data.properties.filed_content != 'Hide'">
    
    <span
      v-if="
        hasLabel && !fromRepeatable && data.properties.filed_content != 'Hide'
      "
      >{{ label || data.label }}</span
    >
    <span
      v-if="data.validations.required && hasLabel && !isView"
      style="color: red; font-weight: bold"
    >
      *
    </span>
    <el-popover
      v-if="data.description"
      placement="top-start"
      trigger="hover"
      :content="data.description"
    >
      <i class="el-icon-info" slot="reference"></i>
    </el-popover>
    <div v-if="getParentDetails">
      <el-link @click="openDialog" class="hyperlnk">
        Download Attachments</el-link
      >
    </div>
    <div v-else>
      <div
        v-if="
          data.validations.multiple &&
          !isView &&
          data.properties.filed_content != 'Hide' &&
          data.input_type != 'ENTITY_VARIABLE' &&
          !checkReadonly()
        "
      >
        <el-upload
          :style="getStyle"
          :on-change="upload"
          class="avatar-uploader"
          :show-file-list="false"
          multiple
          :file-list="fileList"
          ref="upload"
          action="/upload"
          :limit="data.validations.max_document_files"
          :accept="stringifiedArray"
          :on-exceed="handleExceed"
          :on-remove="removeFile"
          :class="{ 'disabled-div': readonly() }"
          @change="applyFormRules"
          :disabled="isFromDocument ? isDisable : checkReadonly()"
          :auto-upload="false"
          v-loading="uploadLoadig"
        >
          <img
            v-if="
              !form[data.key] &&
              data.input_type != 'ENTITY_VARIABLE' &&
              !checkReadonly()
            "
            src="@/assets/img/icons/upload-icon.svg"
            alt="icon"
          />
          <span
            v-else-if="
              form &&
              form[data.key] &&
              form[data.key].files &&
              form[data.key].files.length &&
              form[data.key].files.length > 0 &&
              !checkReadonly()
            "
            @click.stop="uploadDocumentsPopup()"
          >
            {{ form[data.key].files[0].name }}
            <el-tag
              style="cursor: pointer; margin-top: -14px"
              class="ml-1"
              :value="this.files.length"
              v-if="this.files.length > 1 && !checkReadonly()"
              @click.stop="uploadDocumentsPopup()"
            >
              +{{ this.files.length - 1 }}
            </el-tag>
          </span>
          <!-- </span> -->
          <i
            v-if="data.input_type != 'ENTITY_VARIABLE' && !checkReadonly()"
            class="el-icon-upload2 ml-1"
          ></i>
        </el-upload>
      </div>
      <div
        v-else-if="
          !isView &&
          data.properties.filed_content != 'Hide' &&
          !data.validations.multiple &&
          data.files &&
          data.files.length &&
          !form[data.key] &&
          !checkReadonly() &&
          isDisable
            ? !isAfterDocumentSend
            : ''
        "
      >
        <el-upload
          ref="upload"
          action="/upload"
          :style="getStyle"
          :accept="stringifiedArray"
          :on-change="upload"
          :auto-upload="false"
          class="avatar-uploader"
          :disabled="isFromDocument ? isDisable : checkReadonly()"
          :show-file-list="false"
          v-loading="uploadLoadig"
          :file-list="fileList"
          @change="applyFormRules"
          :class="{ 'disabled-div': readonly() }"
          :limit="1"
          :on-remove="removeFile"
        >
          <img
            v-if="data.files && !data.files.length"
            src="@/assets/img/icons/upload-icon.svg"
            alt="icon"
          />
          <span v-else>
            {{ data.files[0].name }}
            <i class="el-icon-upload2"></i>
          </span>
        </el-upload>
      </div>
      <div
        v-else-if="
          !isView &&
          !data.validations.multiple &&
          !checkReadonly() &&
          data.properties.filed_content != 'Hide'
        "
      >
        <el-upload
          ref="upload"
          action="/upload"
          :style="getStyle"
          :on-change="upload"
          :accept="stringifiedArray"
          class="avatar-uploader"
          @change="applyFormRules"
          :disabled="isFromDocument ? isDisable : checkReadonly()"
          :show-file-list="false"
          v-loading="uploadLoadig"
          :auto-upload="false"
          :class="{ 'highlight-border': highlight }"
        >
          <!-- {{!form[data.key]}} -->
          <img
            v-if="!form[data.key]"
            src="@/assets/img/icons/upload-icon.svg"
            alt="icon"
          />
          <span v-else>
            {{ getFileName | truncate(10, "...") }}
            <i class="el-icon-upload2"></i>
          </span>
        </el-upload>
      </div>
    </div>
    <div
      v-if="
        (isView ||
          checkReadonly() ||
          (data.allow_user_download && !isFromAddingFields)) &&
        data.files &&
        data.files.length &&
        !form[data.key] &&
        data.properties.filed_content != 'Hide'
      "
    >
      <p v-for="(file, index) of data.files" :key="index">
        <el-button
          type="text"
          v-if="file && file.name"
          @click="onPreview(file)"
        >
          {{ file.name | truncate(10, "...") }}
          <i class="el-icon-download"></i>
        </el-button>
        <template v-else>-</template>
      </p>
    </div>
    <div
      v-else-if="
        isView ||
        checkReadonly() ||
        (data.allow_user_download &&
          !isFromAddingFields &&
          data.properties.filed_content != 'Hide')
      "
      class="avatar-uploader"
    >
      <div
        class="d-flex"
        v-if="
          form[data.key] &&
          form[data.key].files &&
          form[data.key].files.length &&
          form[data.key].files.length > 0 &&
          (data.files || !data.files)
        "
        :style="getStyle"
      >
        <p v-for="(file, index) of form[data.key].files" :key="index">
          <el-button
            type="text"
            style="margin-top: 20px"
            v-if="file && file.name && index < limit"
            @click="onPreview(file)"
            class="mb-1"
          >
            {{ file.name | truncate(10, "...") }}
            <i class="el-icon-download"></i>
          </el-button>
        </p>
        <el-tag
          style="cursor: pointer; margin-top: 20px"
          class="ml-1"
          :value="form[data.key].files.length"
          v-if="form[data.key].files.length > 1"
          @click="downloadDocumentsPopup()"
        >
          +{{ form[data.key].files.length - 1 }}
        </el-tag>
      </div>
      <template v-else>-</template>
    </div>
    <dialog-component
      :modal="false"
      :visible="downloadDocuments"
      :title="'Download Documents'"
      :containerWidth="'500px'"
      @before-close="closeDownloadDocuments"
    >
      <div class="download-attachments">
        <el-row>
          <el-input
            class="search mb-1"
            placeholder="Search"
            v-model="searchDocument"
            clearable
            @input="searchDocumentName"
            prefix-icon="el-icon-search"
          ></el-input>
        </el-row>
        <div class="sidemenu">
          <el-table
            border
            :data="documentSearchData"
            :header-row-class-name="'my-header-row'"
            :header-cell-style="headerCellStyle"
            class="tableSty"
            :show-header="true"
          >
            <el-table-column class="labelSty" label="Document Name">
              <template slot-scope="scope">
                <p style="font-size: 14px" slot="reference">
                  {{ scope.row.name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Action" width="150">
              <template slot-scope="scope">
                <ul class="action-buttons">
                  <li>
                    <button class="btn btn-light btn-sm m-lr-1" type="button">
                      <span
                        class="text-muted"
                        @click="onPreview(scope.row)"
                        title="preview"
                      >
                        <i class="fa fa-eye"></i>
                      </span>
                    </button>
                  </li>
                  <li>
                    <button class="btn btn-light btn-sm m-lr-1" type="button">
                      <span
                        @click="onDownload(scope.row)"
                        target="_blank"
                        ref="downloadLink"
                        title="download"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                    </button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </dialog-component>
    <dialog-component
      :modal="false"
      :visible="uploadDocuments"
      :title="'Upload Documents'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @before-close="closeUploadDocuments"
    >
      <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchUploadDocument"
          clearable
          @input="searchUploadDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>
      <div class="sidemenu">
        <el-table
          border
          :data="documentUploadData"
          :header-row-class-name="'my-header-row'"
          :header-cell-style="headerCellStyle"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span
                      class="text-muted"
                      @click="onDelete(scope.row)"
                      target="_blank"
                      title="Delete"
                      ref="downloadLink"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="uploadDocuments = false"> Close </el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="dialogVisible"
      :title="'Download Attachments'"
      :containerWidth="'600px'"
      @before-close="backToEntityInfo"
    >
      <div class="multi-doc-dialog">
        <el-button
          v-for="(ele, i) in form[data.key]"
          :key="i+'_file'"
          @click="onDownload(ele)"
          target="_blank"
          ref="downloadLink"
          type="info"
          plain
          size="mini"
          class="el-icon-download"
          >{{ ele && ele.name ? ele.name : `File ${(i+1)}` }}</el-button
        >
      </div>
    </dialog-component>
  </div>
</section>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import {bus} from "../../../main";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      uploadLoadig: false,
      validations: [],
      fileName: "",
      files: [],
      multipleFileNames: [],
      tagType: [""],
      limit: 1,
      downloadDocuments: false,
      documentsData: [],
      fileList: [],
      documentSearchData: [],
      searchDocument: "",
      dialogVisible: false,
      uploadDocuments: false,
      searchUploadDocument: "",
      documentUploadData: [],
    };
  },
  mixins: [userPermissionsHelper],
  components: {
    DialogComponent: () =>
      import("@/components/customComponents/CustomDialog.vue"),
  },
  props: [
    "data",
    "label",
    "checkIsDisabled",
    "form",
    "isView",
    "hasLabel",
    "isDisable",
    "isFromDocument",
    "isExecute",
    "templatesData",
    "fromRepeatable",
    "isFromAddingFields",
    "isAfterDocumentSend",
    "filesInfo",
    "colorFields",
    "fieldsData",
    "highlight",
  ],
  computed: {
    getParentDetails() {
      if (this.data.is_entity_variable && this.data.relationship_key) {
        let parentField = this.fieldsData.find(
          (e) => e.key == this.data.relationship_key
        );
        if (parentField.allow_multiple) {
          return parentField;
        }
      }
      return null;
    },
    stringifiedArray() {
      return this.data.validations.allowed_file_types.join(", ");
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    getStyle() {
      return (
        `height:${this.data.height - 30}px` +
        (this.data.styles && this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "")
      );
    },
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    getFileName() {
      if (
        this.form[this.data.key] &&
        this.form[this.data.key].name &&
        (this.data.files || !this.data.files || !this.data.files.length)
      ) {
        let fileNames =
          this.form &&
          this.form[this.data.key] &&
          this.form[this.data.key].name &&
          this.form[this.data.key].name.split(".");
        if (fileNames && fileNames.length && fileNames.length > 1) {
          let name = fileNames[0];
          let ext = fileNames.pop();
          if (name.length > 20) {
            return name.slice(0, 20) + "." + ext;
          } else {
            return name + "." + ext;
          }
        } else {
          // return name.slice(0, 20);
          return "";
        }
      } else if (
        !this.form[this.data.key] &&
        !this.form[this.data.key].name &&
        this.data.files &&
        this.data.files.length &&
        this.data.files.length == 1
      ) {
        let fileNames = this.data.files[0].name;
        return fileNames;
      } else {
        return "";
      }
    },
  },
  mounted() {
    // console.log("type",this.data.validations.allowed_file_types)
    console.log(this.files)
    if (
      this.form &&
      this.form[this.data.key] &&
      this.form[this.data.key].file &&
      !this.data.validations.multiple
    ) {
      this.form[this.data.key].file = null;

      this.fileName = this.form[this.data.key].file_name
        ? this.form[this.data.key].file_name
        : this.form[this.data.key].name;

      this.fileList = this.form[this.data.key].files;
      // this.filesInfo[this.data.key] = this.form[this.data.key].files;
    }
    if (
      this.form &&
      this.form[this.data.key] &&
      this.form[this.data.key].files &&
      this.data.validations.multiple
    ) {
      this.fileList = this.form[this.data.key].files;
    }
    if (this.fileList && this.fileList.length) {
      this.files = this.files.concat(this.fileList);
    }
    if (
      this.form &&
      this.form[this.data.key] &&
      this.form[this.data.key].files
    ) {
      this.documentsData = this.form[this.data.key].files;
      this.documentSearchData = this.documentsData;
      // this.form[this.data.key].file = null;
      this.multipleFileNames = this.form[this.data.key].files.flatMap((el) => {
        return el.name;
      });
    }
    if (this.data && this.data.files && this.data.files.length > 1) {
      console.log('this.data.files---0---this.data.files',this.data.files)
      this.data.files.file = null;
      this.fileName = this.data.files.file.name;
    }
    this.files = this.documentsData;
    this.documentUploadData = this.files;
  },
  beforeUpdate() {
    // console.log("Form value", this.form[this.data.key]);
    // console.log("Data:", this.data.value);
    // console.log(this.fileName);
  },

  methods: {
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    openDialog() {
      // Set the dialog to be visible when the link is clicked
      this.dialogVisible = true;
    },
    backToEntityInfo() {
      this.dialogVisible = false;
    },
    searchDocumentName() {
      this.documentSearchData = [];
      this.documentSearchData = this.documentsData.filter(
        (e) =>
          e.name.toLowerCase().includes(this.searchDocument.toLowerCase()) ==
          true
      );
    },
    downloadDocumentsPopup() {
      this.documentsData = this.form[this.data.key].files;
      this.documentSearchData = this.documentsData;
      this.downloadDocuments = true;
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    getFileDataName() {
      if (this.form[this.data.key] && this.form[this.data.key].files) {
        this.multipleFileNames = this.form[this.data.key].files.flatMap(
          (el) => {
            return el.name;
          }
        );
      }
    },
    handleExceed(files) {
      this.$message.warning(
        `The limit is ${this.data.validations.min_document_files} - ${this.data.validations.max_document_files}, you selected ${files.length} files this time, add up to ${this.data.validations.max_document_files} totally`
      );
    },
    removeFile(file) {
      console.log(file, this.files);
      const index = this.files.indexOf(file ? file : file.name);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      this.form[this.data.key].files = this.files;
      if (this.filesInfo) {
        this.filesInfo[this.data.key].files = this.files;
      }
      //  this.$set(this.form, this.data.key, {
      //   type: "DOCUMENT",
      //   new: true,
      //   files: this.files,
      // });
      // this.files.forEach((el) => {
      //   const inputElement = document.getElementById(this.files); // Replace 'file-input' with the ID of your file input element
      //   const file = inputElement.files[0]; // Get the first file from the input element
      //   const reader = new FileReader(); // Create a new FileReader object

      //   reader.onload = function (event) {
      //     const rawData = event.target.result; // Get the raw data as a binary string or ArrayBuffer
      //     // Use the raw data as needed
      //        console.log(rawData)
      //   };

      //   reader.readAsArrayBuffer(file); // Read the file contents as an ArrayBuffer

      // });
      // console.log(this.files)
    },
    async upload(file) {
      console.log("Selected File", file);
      this.fileName = file.name;
      if (this.data.validations.multiple) {
        await this.files.push(file);
      } else {
        this.files = [];
        await this.files.push(file);
      }
      this.$set(this.form, this.data.key, {
        type: "DOCUMENT",
        name: file.name,
        file: file,
        new: true,
        file_name: file.name,
        file_type: file.raw.type,
        file_size_in_kb: file.size / 1024,
        raw: file.raw,
        files: this.files,
      });

      if (this.filesInfo) {
        this.$set(this.filesInfo, this.data.key, {
          type: "DOCUMENT",
          name: file.name,
          file: file,
          new: true,
          file_name: file.name,
          file_type: file.raw.type,
          file_size_in_kb: file.size / 1024,
          raw: file.raw,
          files: this.files,
        });
      }
      console.log(
        "Selected File",
        this.files,
        this.form[this.data.key],
        this.filesInfo
      );

      if (file && file.name) {
        this.uploadLoadig = true;
        this.fileName = "";
        await setTimeout(() => {
          let fileNames = file.name.split(".");
          if (fileNames.length > 1) {
            let name = fileNames[0];
            let ext = fileNames.pop();
            if (name.length > 20) {
              this.uploadLoadig = false;
              return (this.fileName = name.slice(0, 20) + "." + ext);
            } else {
              this.uploadLoadig = false;
              return (this.fileName = name + "." + ext);
            }
          } else {
            this.uploadLoadig = false;
            this.fileName = "";
          }
          this.uploadLoadig = false;
        }, 1000);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    readonly() {
      if (this.checkIsDisabled) {
        return true;
      }
    },
    checkReadonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    handleUpload() {
      this.$refs.upload.upload();
    },

    async onDownload(data) {
      console.log(data);
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      console.log("this.getDownloadUrl", this.getDownloadUrl);
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          console.log("this.getDownloadUrlthis.getDownloadUrl", response);
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // console.log("0-0--0-");
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
    },
    uploadDocumentsPopup() {
      this.uploadDocuments = true;
      bus.$emit('openUplaodDocumentsPopup',{
        uploadDocuments:this.uploadDocuments,
        documentUploadData:this.documentUploadData
      })
    },
    onDelete(row) {
      let index = this.files.findIndex((obj) => obj.uid === row.uid);
      this.files.splice(index, 1);
      this.form[this.data.key].files = this.files;
      this.fileList = this.form[this.data.key].files;
    },
    closeUploadDocuments() {
      this.uploadDocuments = false;
    },
    searchUploadDocumentName() {
      this.documentUploadData = [];
      this.documentUploadData = this.files.filter(
        (e) =>
          e.name
            .toLowerCase()
            .includes(this.searchUploadDocument.toLowerCase()) == true
      );
      //  }
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.highlight-border.avatar-uploader {
  border: 1px dashed #ff4040;
}
.multi-doc-dialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .el-link {
    color: blue;
    font-size: larger;
    white-space: nowrap;
    margin-bottom: 5px;
  }

  .el-link__inner {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .el-icon-download {
    font-size: 15px;
    margin-bottom: 15px;
  }
}
.avatar-uploader {
  width: inherit !important;
  justify-content: center !important;
}
.avatar {
  height: inherit !important;
  justify-content: center !important;
}
.attachment_img {
  height: inherit !important;
}
.sidemenu {
  height: 360px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
::v-deep .my-header-row {
  background-color: #1b5ea1;
  font-weight: bold;
}
.labelSty {
  color: #000;
}
.tableSty .el-table__row {
  height: 10px; /* Set the desired height here */
}
.el-dialog {
  z-index: 1000;
}

.hyperlnk {
  color: blue;
}

.download-attachments {
  margin-top: 20px;
}
</style>
